import React, { Component, Fragment } from "react";
import "./Cart.scss";
import "./CartRTL.scss";
import { Util } from "../../util/util";
import axios from "axios";
import api from "../../util/api";
import { trackPromise } from "react-promise-tracker";
import { getTranslatedText as t } from "../../translations/index";

let myDate = new Date();
let currentDate = new Date(`01/01/2000 ${myDate.getHours()}:${myDate.getMinutes()} `)
class Cart extends Component {
  state = {
    allCartItems: [],
    name: "",
    room: "",
    guest: "",
    cartDataToSend: [],
    resCode: "",
    covers: "",
    table: "",
    userMessage: "",
    myOrders: [],
    tableNumber: "",
  };
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let resCode = params.get("rcode");
    let tableNumber = params.get("tn");
    this.setState({ resCode, tableNumber });
    if (!this.props.in_room_dining) {
      let table = params.get("tn");
      this.setState({ table });
    }
    if (localStorage.getItem(resCode + tableNumber + "Orders")) {
      let myOrders = localStorage.getItem(resCode + tableNumber + "Orders");
      myOrders = JSON.parse(myOrders);
      this.setState({ myOrders });
    }

    let cartItem = localStorage.getItem(resCode + tableNumber + "cartItem");
    this.setState({ resCode });
    cartItem = JSON.parse(cartItem);
    this.setState({ allCartItems: cartItem }, () => {
      let cartData = {},
        cartDataToSend = [];
      this.state.allCartItems &&
        this.state.allCartItems.length > 0 &&
        this.state.allCartItems.map((data) => {
          let customizations = [];
          cartData = {
            item_id: data.id,
            item_name: data.name,
            quantity: data.count,
            comment: data.comments ? data.comments : "",
            comment_code: data.comment_code ? data.comment_code : "",
            customizations,
          };
          cartDataToSend.push(cartData);
          data.customizations &&
            data.customizations.map((data2) => {
              data2 &&
                data2.list &&
                data2.list.map((data3) => {
                  if (data3.checked) {
                    let addOns = {
                      item_id: data3.id,
                      item_name: data3.alt_name,
                    };
                    customizations.push(addOns);
                  }
                });
            });
        });
      this.setState({ cartDataToSend });
    });
  }
  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  confirmPlaceOrder = (e) => {
    e.preventDefault();
    Util.confirm(
      `${t("CONFIRMATION")}`,
      `${t("SEND_ORDER")}`,
      `${t("YES_BTN")}`,
      `${t("NO_BTN")}`,
      () => this.placeOrder(),
      () => console.log("")
    );
  };
  placeOrderApi = () => {
    this.props.myEMenu &&
      this.props.myEMenu.map((data) => {
        let openTime = data.work_timings[0].open_time
      let closeTime = data.work_timings[0].close_time 
        if (data.menu_name === this.props.activeMenu) {
        if(currentDate  < new Date(`01/01/2000 ${openTime}`) && currentDate > new Date(`01/01/2000 ${closeTime}`)){
          this.props.closeRestaurentProp();
          return;
        }else{ 
          let checkNumber = "";
    if (this.state.myOrders && this.state.myOrders.length > 0) {
      let l = this.state.myOrders.length;
      checkNumber = this.state.myOrders[l - 1].orderDetails.check_number;
    }
    trackPromise(
      axios
        .post(
          api.orderUrl + this.state.resCode + "/order",
          {
            table_number: this.state.table,
            menu_items: this.state.cartDataToSend,
            guest_count: this.state.covers ? this.state.covers : 0,
            guest_last_name: this.state.name,
            check_number: checkNumber,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Ocp-Apim-Subscription-Key": api.orderApiSubscriptionKey,
              "Cache-Control": 'no-cache'
            },
          }
        )
        .then((res) => {
          if (res.status === 200) {
            let a = {
              items: this.state.allCartItems,
              orderDetails: res.data.transaction_detail,
            };

            this.state.myOrders.push(a);
            localStorage.setItem(
              this.state.resCode + this.state.tableNumber + "Orders",
              JSON.stringify(this.state.myOrders)
            );
            Util.ok(
              `${t("SUCCESS_BTN")}`,
              `${
                this.props.deliveryTime || this.props.deliveryTime > 0
                  ? `${t("ORDER_PLACED_LABEL")} ${this.props.deliveryTime} ${t(
                      "MINUTES"
                    )}`
                  : `${t("ORDER_PLACED")}`
              }`,
              `${t("OK_BTN")}`,
              () => {
                window.location.href =
                  "/?rcode=" +
                  this.state.resCode +
                  "&tn=" +
                  this.state.tableNumber;
                localStorage.removeItem(
                  this.state.resCode + this.state.tableNumber + "cartItem"
                );
              }
            );
          }
        })
        .catch((err) => {
          if (err.response.status === 401) {
            if (err.response.data.error.code === 700) {
              Util.ok(
                `${t("UNAUTHORISED_MSG")}`,
                `${t("NAME_MISMATCH")}`,
                `${t("OK_BTN")}`,
                () => console.log("error")
              );
            } else if (err.response.data.error.code === 702) {
              Util.ok(
                `${t("UNAUTHORISED_MSG")}`,
                `${t("UNAUTHORISED")}`,
                `${t("OK_BTN")}`,
                () => console.log("error")
              );
            }
          } else if (err.response.status === 500) {
            Util.ok(
              `${t("ERROR")}`,
              `${t("WENT_WRONG")}`,
              `${t("OK_BTN")}`,
              () => console.log("error", err.response)
            );
          } else if (err.response.status === 403) {
            if (err.response.data.error.code === 701) {
              Util.ok(
                `${t("FORBIDDEN")}`,
                `${t("RESTAURANT_NOT_OPEN")}`,
                `${t("OK_BTN")}`,
                () => {
                  window.location.href =
                    "/?rcode=" +
                    this.state.resCode +
                    "&tn=" +
                    new URLSearchParams(window.location.search).get("tn");
                }
              );
            }
          } else {
            Util.ok(
              `${t("ERROR")}`,
              `${t("WENT_WRONG")}`,
              `${t("OK_BTN")}`,
              () => console.log("error")
            );
          }
        })
    );
        }
      }
      });

    
  };
  placeOrder = () => {
    if (this.props.in_room_dining) {
      if (this.state.name && this.state.table) {
        this.placeOrderApi();
      } else {
        this.setState({ userMessage: `${t("INPUT_MSG")}` });
      }
    } else {
      this.placeOrderApi();
    }
  };
  deleteItem = (index) => {
    let finalCartData = this.state.allCartItems.filter((val, i) => i !== index);
    this.setState({ allCartItems: finalCartData }, () => {
      localStorage.setItem(
        this.state.resCode + this.state.tableNumber + "cartItem",
        JSON.stringify(this.state.allCartItems)
      );
      // if(finalCartData.length === 0){
      //   window.location.reload(false);
      // }
      let cartData = {},
      cartDataToSend = [];
    this.state.allCartItems &&
      this.state.allCartItems.length > 0 &&
      this.state.allCartItems.map((data) => {
        let customizations = [];
        cartData = {
          item_id: data.id,
          item_name: data.name,
          quantity: data.count,
          comment: data.comments ? data.comments : "",
          comment_code: data.comment_code ? data.comment_code : "",
          customizations,
        };
        cartDataToSend.push(cartData);
        data.customizations &&
          data.customizations.map((data2) => {
            data2 &&
              data2.list &&
              data2.list.map((data3) => {
                if (data3.checked) {
                  let addOns = {
                    item_id: data3.id,
                    item_name: data3.alt_name,
                  };
                  customizations.push(addOns);
                }
              });
          });
      });
    this.setState({ cartDataToSend });
    });
  };
  render() {
    let totalPrice = 0,
      itemPrice1 = 0;
    this.state.allCartItems &&
      this.state.allCartItems.length > 0 &&
      this.state.allCartItems.map((data) => {
        let addOnPrice1 = 0;
        data.customizations &&
          data.customizations.length > 0 &&
          data.customizations.map((data2) => {
            data2 &&
              data2.list &&
              data2.list.map((data3) => {
                if (data3.checked) {
                  if (data3.price) {
                    addOnPrice1 = data.count * (addOnPrice1 + data3.price);
                  }
                }
              });
          });
        itemPrice1 = data.count * data.price;
        totalPrice = totalPrice + itemPrice1 + addOnPrice1;
      });
    return (
      <section className="cart-block">
        <div className="close-icon" onClick={this.props.closeCart}>
          <i className="material-icons">close</i>
        </div>
        <div className="second-inner-cart-wrapper">
          <div className="cart-head">
            <h1> {t("PLACE_ORDER")}</h1>
            {!this.props.expandCart ? (
              <span className="price">
                {this.props.currencyCode} {totalPrice}
              </span>
            ) : (
              <span className="clear" onClick={this.props.confirmDelete}>
                {t("CLEAR_CART")}
              </span>
            )}
          </div>
        </div>
        <div className="cart-wrapper">
          <div className="cart-inner-wrapper">
            <div className="items-list">
              {this.state.allCartItems &&
                this.state.allCartItems.length > 0 &&
                this.state.allCartItems.map((data, index) => {
                  let addOnPrice = 0,
                    itemPrice;
                  itemPrice = data.count * data.price;
                  return (
                    <div className="item" key={index}>
                      <div className="name-count">
                        <div className="edit-cart">
                          <i
                            className="material-icons"
                            onClick={() =>
                              this.props.editCart(data, data.id, data.count)
                            }
                          >
                            edit
                          </i>
                        </div>
                        <div className="delete-item">
                          <i
                            className="material-icons"
                            onClick={() => this.deleteItem(index)}
                          >
                            delete_outline
                          </i>
                        </div>
                        <div className="item-count">{data.count}</div>X
                        <div className="item-name">
                          <div className="name">{data.name}</div>
                          <div className="add-ons">
                            {data.customizations &&
                              data.customizations.map((data2, index2) => {
                                return (
                                  <div className="group" key={index2}>
                                    {data2 &&
                                      data2.list &&
                                      data2.list.map((data3, index3) => {
                                        if (data3.checked) {
                                          if (data3.price) {
                                            addOnPrice =
                                              data.count *
                                              (addOnPrice + data3.price);
                                          }
                                        }
                                        return (
                                          <Fragment key={index3}>
                                            {data3.checked && (
                                              <Fragment>
                                                <span key={index3}>
                                                  {data3.name}
                                                </span>
                                                <span className="seperator">
                                                  ,{" "}
                                                </span>
                                              </Fragment>
                                            )}
                                          </Fragment>
                                        );
                                      })}
                                  </div>
                                );
                              })}
                          </div>
                          {data.comments && (
                            <div className="comments">{data.comments}</div>
                          )}
                        </div>
                      </div>
                      <div className="price">
                        {/* {this.props.currencyCode}  */}
                        {itemPrice + addOnPrice}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="total-block">
              <span>{t("TOTAL")}</span>
              <span>
                {this.props.currencyCode} {totalPrice}
              </span>
            </div>
            <form onSubmit={this.confirmPlaceOrder}>
              <div className="guest-covers">
                <h2>{t("GUEST_COVER_HEADING")}</h2>
                {this.props.in_room_dining && (
                  <Fragment>
                    <div className="cover-input">
                      <label>
                        {t("LAST_NAME")}
                        <span style={{ color: "red", paddingLeft: "5px" }}>
                          *
                        </span>
                      </label>

                      <input
                        type="text"
                        name="name"
                        value={this.state.name}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    {this.props.helpTextName && (
                      <div className="help-text">{this.props.helpTextName}</div>
                    )}
                    <div className="cover-input">
                      <label>
                        {t("ROOM_NUMBER")}
                        <span style={{ color: "red", paddingLeft: "5px" }}>
                          *
                        </span>
                      </label>
                      <input
                        type="number"
                        name="table"
                        value={this.state.table}
                        onChange={this.handleInputChange}
                      />
                    </div>
                    {this.props.helpTextRoom && (
                      <div className="help-text">{this.props.helpTextRoom}</div>
                    )}
                  </Fragment>
                )}

                <div className="cover-input">
                  <label>{t("GUEST_COVER_INPUT_CONTENT")}</label>
                  <input
                    type="tel"
                    name="covers"
                    value={this.state.covers}
                    // pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
                    maxlength="1"
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              {this.state.allCartItems && this.state.allCartItems.length > 0 && (
                <div
                  className={`order-btn  ${
                    (this.state.name && this.state.table) ||
                    !this.props.in_room_dining
                      ? "active"
                      : ""
                  }`}
                >
                  <input type="submit" value={`${t("PLACE_ORDER_BTN")}`} />
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    );
  }
}

export default Cart;
