import React, { Component, Fragment } from "react";
import "./MenuPage.scss";
import FoodModal from "./FoodModal";
import { Util } from "../../util/util";
import Cart from "../Cart";
import _ from "lodash";
import "./MenuPageRTL.scss";
import { getTranslatedText as t } from "../../translations/index";
import { connect } from "react-redux";

let myDate = new Date();
let currentDate = new Date(`01/01/2000 ${myDate.getHours()}:${myDate.getMinutes()} `)
class MenuPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "",
      isFoodModalOpen: false,
      dataToSend: "",
      selectedFood: "-1",
      cartValue: 0,
      dummy: false,
      isBottomCartVisible: false,
      restaurantCode: "",
      instructions: "",
      isAddClicked: false,
      expandCart: false,
      edit: false,
      showCustomization: true,
      resetCounter: false,
      isCustomizationPresent: false,
      foodCounter: 1,
      isEditCartClicked: false,
      newItemCreated: false,
      addedFoodImage: "",
      table: "",
      error: false,
      tableNumber: "",
      isRestaurantOpen: false,
      activeMenu: "",
      selectedMenu: "",
    };
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let restaurantCode = params.get("rcode");
    let tableNumber = params.get("tn");
    this.setState({ restaurantCode, tableNumber }, () => {
      this.firstLoadFunction();
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.firstLoadFunction();

    }
  }

  closeRestaurentProp = () => {
    this.setState({ isRestaurantOpen: false });
    this.closeCart()
  }

  firstLoadFunction = () => {
    this.props.myEMenu &&
      this.props.myEMenu.map((data) => {
        let openTime = data.work_timings[0].open_time
        let closeTime = data.work_timings[0].close_time
        // alert(data.work_timings[0].open_for_order);

        if (data.work_timings[0].open_for_order && !this.state.isRestaurantOpen) {
          if (data.menu_name === this.props.activeMenu) {
            if (currentDate < new Date(`01/01/2000 ${openTime}`) && currentDate > new Date(`01/01/2000 ${closeTime}`)) {
              this.setState({ isRestaurantOpen: false });
              return;
            }
            else {
              this.setState({ isRestaurantOpen: true });
            }
          }
        }

        if (data.menu_name === this.props.activeMenu) {
          this.setState({ activeMenu: data }, () => {
            let firstLoadCategory =
              this.state.activeMenu &&
              this.state.activeMenu.menu_sections[0].section_name;
            this.setActiveElement(firstLoadCategory, false);
          });
        }
      });

    let cart = localStorage.getItem(
      this.state.restaurantCode + this.state.tableNumber + "cartItem"
    );
    cart = cart ? JSON.parse(cart) : [];
    this.setState({ cart });
  };
  addToCart = (itemData) => {
    this.props.myEMenu &&
      this.props.myEMenu.map((data) => {
        let openTime = data.work_timings[0].open_time
        let closeTime = data.work_timings[0].close_time
        if (data.menu_name === this.props.activeMenu) {
          if (currentDate < new Date(`01/01/2000 ${openTime}`) && currentDate > new Date(`01/01/2000 ${closeTime}`)) {
            this.setState({ isRestaurantOpen: false });
            return;
          } else {
            if (itemData && itemData.customizations) {
              let kk;
              if (this.state.foodCounter !== 0) {
                kk = itemData.customizations.every(function (ele, index) {
                  if (ele.min) {
                    if (ele.checkCount >= ele.min) {
                      ele.err = false;
                      return true;
                    } else {
                      ele.err = true;
                      setTimeout(() => {
                        let zz = document.getElementById("error-div");
                        zz.scrollIntoView();
                      }, 0);
                      return false;
                    }
                  } else {
                    ele.err = false;
                    return true;
                  }
                });
              } else {
                this.setCartValue(itemData);
              }
              if (kk) {
                this.setState({ error: false });
                this.setCartValue(itemData);
              } else {
                this.setState({ error: true });
              }
            } else {
              this.setCartValue(itemData);
            }
          }
        }
      });
  };
  setCartValue = (itemData) => {
    let isIdPresent = false,
      cartDataHaveToUpdate = "",
      indexOfDataInCart = 0,
      freshData = {},
      index;
    let cart =
      this.state.cart && this.state.cart.find((val) => val.id === itemData.id);
    let currentItem;
    // Checking if an item with same customization is already present in the cart
    if (this.state.resetCounter) {
      freshData = _.cloneDeep(this.state.dataToSend);
    }
    let isCustomizationPresent = false,
      item_customization,
      data_customization,
      item_comment,
      data_comment;
    if (this.state.cart.length > 0) {
      for (let i in this.state.cart) {
        if (this.state.cart[i].id === itemData.id) {
          item_customization = this.state.cart[i].customizations;
          data_customization = itemData.customizations;
          item_comment = this.state.cart[i].comments;
          data_comment = itemData.comments;
          isCustomizationPresent =
            _.isEqual(item_customization, data_customization) &&
            _.isEqual(item_comment, data_comment);
        }
        if (isCustomizationPresent) {
          currentItem = this.state.cart[i];
          index = i;
          break;
        }
      }
    }

    if (cart !== undefined) {
      isIdPresent = true;
      indexOfDataInCart = this.state.cart && this.state.cart.indexOf(cart);
      cartDataHaveToUpdate = this.state.cart[indexOfDataInCart];
    }

    if (isIdPresent) {
      if (isCustomizationPresent) {
        if (this.state.isEditCartClicked) {
          itemData.count = this.state.foodCounter;
          let x = this.state.cart;
          x[index] = itemData;
          this.setState({ cart: x });
          if (this.state.foodCounter === 0) {
            let a = this.state.cart;
            a.splice(index, 1);
          }
        } else {
          if (this.state.resetCounter) {
            currentItem.count = currentItem.count + this.state.foodCounter;
          } else {
            cartDataHaveToUpdate.count =
              cartDataHaveToUpdate.count + this.state.foodCounter;
          }
        }
      } else {
        if (!isCustomizationPresent) {
          if (this.state.foodCounter === 0) {
          } else if (this.state.isEditCartClicked) {
            cartDataHaveToUpdate = itemData;
            cartDataHaveToUpdate.count = this.state.foodCounter;
            let x = this.state.cart;
            x[indexOfDataInCart] = cartDataHaveToUpdate;
            this.setState({ cart: x });
          } else {
            freshData.count = this.state.foodCounter;
            this.state.cart.push(freshData);
            this.setState(
              { newItemCreated: true, addedFoodImage: itemData.image },
              () => {
                setTimeout(() => {
                  this.setState({ newItemCreated: false });
                }, 900);
              }
            );
          }
        } else {
          cartDataHaveToUpdate.count = this.state.foodCounter;
          if (this.state.foodCounter === 0) {
            let cartData = this.state.cart.filter(
              (val) => val.id !== itemData.id
            );
            let allData = cartData === undefined ? [] : cartData;
            this.setState({ cart: allData });
          }
        }
      }
    } else {
      if (this.state.foodCounter !== 0) {
        itemData.count = this.state.foodCounter;
        this.state.cart.push(itemData);
        this.setState(
          { newItemCreated: true, addedFoodImage: itemData.image },
          () => {
            setTimeout(() => {
              this.setState({ newItemCreated: false });
            }, 900);
          }
        );
      }
    }

    localStorage.setItem(
      this.state.restaurantCode + this.state.tableNumber + "cartItem",
      JSON.stringify(this.state.cart)
    );
    this.setState({
      isFoodModalOpen: false,
      selectedFood: "-1",
      isBottomCartVisible: true,
      isAddClicked: true,
      dataToSend: "",
      isEditCartClicked: false,
    });
  };
  handleItemCounter = (type, data) => {
    this.setState({ dummy: !this.state.dummy });
    if (type === "plus") {
      this.setState({ foodCounter: this.state.foodCounter + 1 });
    } else if (type === "minus") {
      this.setState({ foodCounter: this.state.foodCounter - 1 });
    }
  };

  handleAddOns = (e, addOnData, foodData, min, max, checkCount) => {
    if (checkCount > max) {
      foodData &&
        foodData.list.map((data) => {
          data.checked = false;
        });
    }
    addOnData.checked = e.target.checked;
    this.setState({ dummy: !this.state.dummy });
  };

  setActiveElement = (category, isCategoryClicked) => {
    this.setState({ activeTab: category }, () => {
      let ele = document.getElementById("category-tabs");
      if (this.state.isRestaurantOpen) {
        ele.scrollLeft = 0;
      }
      if (isCategoryClicked) {
        let zz = document.getElementById("menu-page-block");
        window.scroll({
          top: zz.offsetTop,
          behavior: "smooth",
        });
      }
    });
  };
  handleMenuScroll = (data) => {
    let id = data.name.replace(/\s/g, "");
    var element = document.getElementById(`${id}`);
    window.scroll({
      top: element.offsetTop - 150,
      behavior: "smooth",
    });
  };

  openFoodModal = (data, id, cartValue) => {
    this.setState({ newItemCreated: false });
    let count = 1;
    this.state.cart.length > 0 &&
      this.state.cart.map((item) => {
        if (item.id === id) {
          count = item.count;
        }
      });
    this.setState({ foodCounter: count });

    let dataCopy = _.cloneDeep(data);
    dataCopy.customizations &&
      dataCopy.customizations.map((i) => {
        i.list &&
          i.list.map((j) => {
            j.checked = false;
          });
      });
    this.state.cart.map((data2) => {
      if (data2.id === id) {
        this.setState({ showCustomization: false });
      }
    });
    this.setState({
      isFoodModalOpen: true,
      dataToSend: dataCopy,
      selectedFood: id,
      expandCart: false,
      dataToEdit: "",
    });
  };
  closeFoodModal = () => {
    this.setState({
      isFoodModalOpen: false,
      selectedFood: "-1",
      cart: localStorage.getItem(
        this.state.restaurantCode + this.state.tableNumber + "cartItem"
      )
        ? JSON.parse(
          localStorage.getItem(
            this.state.restaurantCode + this.state.tableNumber + "cartItem"
          )
        )
        : [],
      instructions: "",
      dataToSend: "",
      showCustomization: true,
      resetCounter: false,
      isEditCartClicked: false,
      error: false,
    });
  };
  goToCart = () => {
    this.setState({ expandCart: true });
  };
  closeCart = () => {
    this.setState({ expandCart: false });
    this.firstLoadFunction();
  };
  confirmDelete = () => {
    Util.confirm(
      `${t("CONFIRMATION")}`,
      `${t("CLEAR_CART_CONFIRMATION")}`,
      `${t("YES_BTN")}`,
      `${t("NO_BTN")}`,
      () => this.clearEntireCart(),
      () => console.log("")
    );
  };
  clearEntireCart = () => {
    localStorage.removeItem(
      this.state.restaurantCode + this.state.tableNumber + "cartItem"
    );
    this.setState({ expandCart: false, cart: [] });
  };
  editCart = (data, id, cartValue, command) => {
    if (command !== undefined) {
      this.setState(
        {
          isFoodModalOpen: false,
        },
        () => {
          this.setState({
            isFoodModalOpen: true,
            dataToEdit: data,
            dataToSend: "",
            selectedFood: id,
            foodCounter: cartValue,
            isEditCartClicked: true,
            showCustomization: true,
          });
        }
      );
    } else {
      this.setState({
        isFoodModalOpen: true,
        dataToEdit: data,
        dataToSend: "",
        selectedFood: id,
        foodCounter: cartValue,
        expandCart: false,
        isEditCartClicked: true,
      });
    }
  };
  addCustomization = (id, cartValue) => {
    this.setState({
      showCustomization: true,
      resetCounter: true,
      foodCounter: 1,
    });
  };

  render() {

    let totalPrice = 0,
      itemPrice = 0;
    this.state.cart &&
      this.state.cart.length > 0 &&
      this.state.cart.map((data) => {
        let addOnPrice = 0;
        data.customizations &&
          data.customizations.length > 0 &&
          data.customizations.map((data2) => {
            data2 &&
              data2.list &&
              data2.list.map((data3) => {
                if (data3.checked) {
                  if (data3.price) {
                    addOnPrice = data.count * (addOnPrice + data3.price);
                  }
                }
              });
          });
        itemPrice = data.count * data.price;
        totalPrice = totalPrice + itemPrice + addOnPrice;
      });
    if (this.state.expandCart || this.state.isFoodModalOpen) {
      document.querySelector("body").style.overflowY = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "auto";
    }
    return (
      <Fragment>
        <section className="menu-page-block" id="menu-page-block">
          <div className="menu-page-wrapper">
            {!this.state.isRestaurantOpen ? (
              <div className="rest-not-open">
                <div className="not-open-wrapper">
                  <h2>{t("RESTAURANT_NOT_OPEN")}</h2>
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="menu-header">
                  <div className="category-tabs">
                    {this.state.activeMenu.menu_sections &&
                      this.state.activeMenu.menu_sections.length > 0 &&
                      this.state.activeMenu.menu_sections.map((data, index) => {
                        return (
                          <button
                            className={`category ${this.state.activeTab === data.section_name
                              ? "active"
                              : ""
                              }`}
                            key={index}
                            onClick={() =>
                              this.setActiveElement(data.section_name, true)
                            }
                          >
                            {data.section_name}
                          </button>
                        );
                      })}
                  </div>
                  <div className="sub-menu-category-tabs" id="category-tabs">
                    {this.state.activeMenu &&
                      this.state.activeMenu.menu_sections &&
                      this.state.activeMenu.menu_sections.length > 0 &&
                      this.state.activeMenu.menu_sections.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            {data.section_name === this.state.activeTab &&
                              data.menu_categories &&
                              data.menu_categories.length > 0 &&
                              data.menu_categories.map((data2, index2) => {
                                return (
                                  <div
                                    key={index2}
                                    className="sub-menu"
                                    onClick={() => {
                                      this.handleMenuScroll(data2);
                                    }}
                                  >
                                    {data2.name}
                                  </div>
                                );
                              })}
                          </Fragment>
                        );
                      })}
                  </div>
                </div>
                <div className="full-menu-section">
                  {this.state.activeMenu &&
                    this.state.activeMenu.menu_sections &&
                    this.state.activeMenu.menu_sections.length > 0 &&
                    this.state.activeMenu.menu_sections.map((data, index) => {
                      return (
                        <Fragment key={index}>
                          {data.section_name === this.state.activeTab &&
                            data.menu_categories &&
                            data.menu_categories.length > 0 &&
                            data.menu_categories.map((data2, index2) => {
                              return (
                                <div
                                  className="menu-category"
                                  key={index2}
                                  id={
                                    data2.name && data2.name.replace(/\s/g, "")
                                  }
                                >
                                  <h4>{data2.name}</h4>
                                  <div className="menu-hr"></div>
                                  {data2 &&
                                    data2.items &&
                                    data2.items.map((data3, index3) => {
                                      let cartValue = 0;
                                      let id = data3.id;
                                      for (let i in this.state.cart) {
                                        if (this.state.cart[i].id === id) {
                                          if (
                                            this.state.toBeAdded &&
                                            this.state.toBeAdded.customizations
                                          ) {
                                            if (
                                              _.isEqual(
                                                this.state.cart[i]
                                                  .customizations,
                                                this.state.toBeAdded
                                                  .customizations
                                              )
                                            ) {
                                              cartValue = this.state.toBeAdded
                                                .count;
                                            }
                                          }

                                          cartValue = this.state.cart[i].count;
                                        }
                                      }
                                      return (
                                        <div
                                          className="menu-catalog-wrapper"
                                          key={index3}
                                        >
                                          <div
                                            className="menu-catalog"
                                            onClick={() =>
                                              this.openFoodModal(
                                                data3,
                                                data3.id,
                                                cartValue
                                              )
                                            }
                                          >
                                            {data3.image && (
                                              <div
                                                className="food-image"
                                                style={{
                                                  backgroundImage: `url(${data3.image})`,
                                                }}
                                              ></div>
                                            )}
                                            <div
                                              className="food-content"
                                              id={data3.id}
                                            >
                                              <div className="name">
                                                {data3.name}
                                              </div>
                                              <div className="price">
                                                {this.props.currencyCode}{" "}
                                                {data3.price}
                                              </div>
                                              {
                                                <div style={{ display: 'flex', marginBottom: '5px' }}>
                                                  {data3.allergy_tags &&
                                                    data3.allergy_tags.map((data4, index4) => {
                                                      return (
                                                        <div key={index4}>
                                                          <img src={data4.icon} style={{ height: '20px', width: '20px', marginRight: '7px', borderRadius: '50%' }} />
                                                        </div>
                                                      )
                                                    })
                                                  }
                                                  {
                                                    data3.food_tags &&
                                                    data3.food_tags.map((data4, index4) => {
                                                      return (
                                                        <div key={index4}>
                                                          <img src={data4.icon} style={{ height: '20px', width: '20px', marginRight: '7px', borderRadius: '50%' }} />
                                                        </div>
                                                      )
                                                    })
                                                  }
                                                </div>
                                              }
                                              {data3.description && (
                                                <div className="desc">
                                                  {data3.description}
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                          {this.state.isFoodModalOpen &&
                                            this.state.selectedFood ===
                                            data3.id && (
                                              <FoodModal
                                                closeFoodModal={
                                                  this.closeFoodModal
                                                }
                                                dataToSend={
                                                  this.state.dataToSend
                                                }
                                                isFoodModalOpen={
                                                  this.state.isFoodModalOpen
                                                }
                                                handleItemCounter={
                                                  this.handleItemCounter
                                                }
                                                noOfItems={
                                                  this.state.foodCounter
                                                }
                                                selectedFood={
                                                  this.state.selectedFood
                                                }
                                                setCartValue={this.addToCart}
                                                handleAddOns={this.handleAddOns}
                                                onChangeHandler={
                                                  this.onChangeHandler
                                                }
                                                instructions={
                                                  this.state.instructions
                                                }
                                                dataToEdit={
                                                  this.state.dataToEdit
                                                }
                                                currencyCode={
                                                  this.props.currencyCode
                                                }
                                                addCustomization={
                                                  this.addCustomization
                                                }
                                                showCustomization={
                                                  this.state.showCustomization
                                                }
                                                editCart={this.editCart}
                                                error={this.state.error}
                                                allowOrdering={
                                                  this.props.allowOrdering
                                                }
                                                openForGuestOrder={
                                                  this.props &&
                                                  this.props.infoData &&
                                                  this.props.infoData.open_for_guest_order
                                                }
                                              />
                                            )}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            })}
                        </Fragment>
                      );
                    })}
                </div>
              </Fragment>
            )}
          </div>
        </section>
        {this.state.cart && this.state.cart.length > 0 && (
          <Fragment>
            <div
              className={`bottom-cart-block ${this.state.expandCart ? "hide" : ""
                }`}
              onClick={this.goToCart}
            >
              <div className="cutlery-logo">
                <i className="material-icons">local_dining</i>
              </div>
              {!this.state.expandCart && (
                <div className="inner-cart-wrapper">
                  <div className="cart-head">
                    <h1>{t("PLACE_ORDER")}</h1>
                    {!this.state.expandCart ? (
                      <span className="price">
                        {this.props.currencyCode} {totalPrice}
                      </span>
                    ) : (
                      <span className="clear" onClick={this.confirmDelete}>
                        {/* {Constants.CLEAR_CART_TAG} */}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {this.state.expandCart && (
              <Cart
                myEMenu={this.props.myEMenu}
                activeMenu={this.props.activeMenu}
                openFoodModal={this.openFoodModal}
                editCart={this.editCart}
                currencyCode={this.props.currencyCode}
                in_room_dining={this.props.in_room_dining}
                allowOrdering={this.props.allowOrdering}
                helpTextRoom={this.props.helpTextRoom}
                helpTextName={this.props.helpTextName}
                deliveryTime={this.props.deliveryTime}
                expandCart={this.state.expandCart}
                confirmDelete={this.confirmDelete}
                closeCart={this.closeCart}
                deleteItem={this.deleteItem}
                cart={this.state.cart}
                closeRestaurentProp={this.closeRestaurentProp}
              />
            )}
          </Fragment>
        )}
        <div
          style={{ backgroundImage: `url(${this.state.addedFoodImage})` }}
          className={`cart-ball ${this.state.newItemCreated ? "cart-ball-animation" : ""
            }`}
        ></div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoData: state.infoData,
    activeMenu: state.activeMenu,
    allMenu: state.allMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeLangauge: (activeMenu) => {
      dispatch({ type: "ACTIVE_LANGUAGE", payload: activeMenu });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);
