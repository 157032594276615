const iState = {
  lang: "",
  allLang: ["en"],
  logoUrl: "",
  infoData: "",
  allMenu: "",
  activeMenu: "",
  inRoomDining:"",
};

const reducer = (state = iState, action) => {
  if (action.type === "CHANGE_LANGUAGE") {
    return {
      ...state,
      lang: action.payload,
    };
  } else if (action.type === "ALL_LANGUAGE") {
    return {
      ...state,
      allLang: action.payload,
    };
  } else if (action.type === "GET_LOGO") {
    return {
      ...state,
      logoUrl: action.payload,
    };
  } else if (action.type === "GET_DEFAULT_LANGUAGE") {
    return {
      ...state,
      lang: action.payload,
    };
  } else if (action.type === "GET_INFO") {
    return {
      ...state,
      infoData: action.payload,
    };
  } else if (action.type === "GET_MENU") {
    return {
      ...state,
      allMenu: action.payload,
    };
  } else if (action.type === "ACTIVE_LANGUAGE") {
    return {
      ...state,
      activeMenu: action.payload,
    };
  } else if(action.type === "GET_IN_ROOM_DINING"){
    return{
      ...state,
      inRoomDining: action.payload,
    };
  }
  return state;
};

export default reducer;
