import React, { Component } from "react";
import "./PageHero.scss";
import Arrow from "../../assets/images/icons/arrow.png";
import "./PageHeroRTL.scss";
import { connect } from "react-redux";

class PageHero extends Component {
  constructor() {
    super();
    this.state = {
      dropdownOpen: false,
      selectedMenu: "",
      isMenuSelected: false,
    };
  }
  componentDidMount() {
    if (this.props.allMenu && this.props.allMenu.length > 1) {
      for (let i = 0; i < this.props.allMenu.length; i++) {
        if ((/true/i).test(this.props.allMenu[i].work_timings[0].open_for_order)) {
          this.setState(
            {
              selectedMenu: this.props.allMenu[i].menu_name,
              isMenuSelected: true,
            },
            () => {
              this.props.activeLangauge(this.state.selectedMenu);
            }
          );
          break;
        }
      }
    } else {
      this.props.activeLangauge(
        this.props.allMenu &&
        this.props.allMenu[0] &&
        this.props.allMenu[0].menu_name
      );
    }
  }
  toggleMenuDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  selectMenuType = (menu_name) => {
    this.setState({ selectedMenu: menu_name, isMenuSelected: true }, () => {
      this.props.activeLangauge(this.state.selectedMenu);
    });
  };
  render() {
    const fields = this.props.infoData;
    let hero = fields.image_url;
    let restaurant_location = fields.restaurant_location;
    let restaurant_name = fields.restaurant_name;
    let count = 0;
    this.props.allMenu &&
      this.props.allMenu.length > 1 &&
      this.props.allMenu.map((data) => {
        if (data.work_timings[0].open_for_order) {
          count++;
        }
        return count;
      });

    return (
      <section className="page-hero-block">
        <div
          className="page-hero-wrapper"
          style={{ backgroundImage: `url(${hero})` }}
        >
          <div className="linear-gradient-container"></div>
          <div className="hero-content-wrapper">
            <div className="hotel-name">{restaurant_location}</div>
            <div className="location-name">
              <h1>{restaurant_name}</h1>
            </div>
            {this.props.allMenu && this.props.allMenu.length > 1 && count > 1 && (
              <div
                className="multi-menu-wrapper"
                onClick={this.toggleMenuDropdown}
              >
                <div className="menu-inner-wrapper">
                  <div className="display-label-wrapper">
                    <span className="display-name">
                      {this.state.isMenuSelected
                        ? this.state.selectedMenu
                        : "SELECT MENU"}
                    </span>
                    <span
                      className={`dropdown-icon ${this.state.dropdownOpen ? "rotate" : ""
                        }`}
                    >
                      <img src={Arrow} alt="down-arrow" />
                    </span>
                  </div>
                  <div
                    className={`dropdown-content ${this.state.dropdownOpen ? "show-dropdown" : ""
                      }`}
                  >
                    <ul>

                      {this.props.allMenu &&
                        this.props.allMenu.length > 1 &&
                        this.props.allMenu.map((data, index) => {
                          return (
                            ((/true/i).test(data.work_timings[0].open_for_order)) ? (
                              <li
                                key={index}
                                className={`${data.work_timings[0].open_for_order ? "" : "hidden"
                                  }`}
                                onClick={() =>
                                  this.selectMenuType(data.menu_name)
                                }
                              >
                                {data.menu_name}
                              </li>
                            ) : null
                          );
                          // return (
                          //   <li
                          //     key={index}
                          //     className={`${data.work_timings[0].open_for_order ? "" : "hidden"
                          //       }`}
                          //     onClick={() =>
                          //       this.selectMenuType(data.menu_name)
                          //     }
                          //   >
                          //     {data.menu_name}
                          //   </li>
                          // );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoData: state.infoData,
    allMenu: state.allMenu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    activeLangauge: (activeMenu) => {
      dispatch({ type: "ACTIVE_LANGUAGE", payload: activeMenu });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PageHero);
