import React, { Component } from "react";
import "./Logo.scss";
import { connect } from "react-redux";

class Logo extends Component {
  render() {
    let logo = this.props && this.props.logoUrl ? this.props.logoUrl : "";
    return (
      <div className="logo">
        {this.props && this.props.logoUrl && (
          <img src={logo} alt="logo"/>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    logoUrl: state.logoUrl,
  };
};

export default connect(mapStateToProps, null)(Logo);
