import React, { Component, Fragment } from "react";
import "./Dashboard.scss";
import PageHero from "../PageHero";
import MenuPage from "../MenuPage";
import axios from "axios";
import api from "../../util/api";
import { trackPromise } from "react-promise-tracker";
import PageNotFound from "../PageNotFound";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import { Util } from "../../util/util";
import { getTranslatedText as t } from "../../translations/index";
import Disclaimer from "../../shared/Disclaimer";
import jumeirahLOGO from "./jumeirah-logo.png";


class Dashboard extends Component {
  state = {
    res: false,
    hero: "",
    restaurant_location: "",
    restaurant_name: "",
    apiFail: false,
    myEMenu: "",
    currencyCode: "",
    in_room_dining: false,
    allowOrdering: false,
    helpTextName: "",
    helpTextRoom: "",
    deliveryTime: "",
    restaurantCode: "",
    myorders: [],
    languages: [],
    logo: "",
    menuLanguage: "",
    tableNumber: "",
    isDisclaimerVisible: false,
    isDisclaimerShownForTheDay: false,
    pageTimeout: false,
  };
  componentDidMount() {
    this.checkTimeout();
    let params = new URLSearchParams(window.location.search);
    let restaurantCode = params.get("rcode");
    let tableNumber = params.get("tn");

    let refId = params.get("ref");
    this.setState({ restaurantCode, tableNumber, refId }, () => {
      if (localStorage.getItem("userLang")) {
        let userLang = localStorage.getItem("userLang");
        if (userLang === "ar") {
          document.querySelector("html").setAttribute("dir", "rtl");
          document.getElementById("App").classList.add("lang-rtl");
        }
        this.getMenu(userLang);
      } else {
        this.getMenu(this.props.lang);
      }
    });
    if (localStorage.getItem(`${restaurantCode}Disclaimer`)) {
      this.setState({ isDisclaimerShownForTheDay: true });
    }
    let date = new Date();
    let currentDate =
      date.getDate() + "/" + date.getMonth() + "/" + date.getFullYear();
    if (localStorage.getItem("lastLogged")) {
      let a = localStorage.getItem("lastLogged");
      if (a !== currentDate) {
        localStorage.clear();
      }
    } else {
      localStorage.setItem("lastLogged", currentDate);
    }
  }

  checkTimeout = () => {
    let newDate = new Date();
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let pageLoadingDate = day + "/" + (month + 1) + "/" + year;
    let hours = newDate.getHours();
    let minutes = newDate.getMinutes();
    let seconds = newDate.getSeconds();
    let pageLoadingTime = "";
    if (hours.toString().length === 1) {
      pageLoadingTime = "0" + hours + ":" + minutes + ":" + seconds;
    } else {
      pageLoadingTime = hours + ":" + minutes + ":" + seconds;
    }
    if (sessionStorage.getItem("timeoutData")) {
      let sessionDate = sessionStorage.getItem("timeoutData");
      if (sessionDate.substring(0, sessionDate.indexOf('T')) === pageLoadingDate) {
        let timeData = sessionStorage.getItem("timeoutData").split("T")[1];
        let setHours = timeData.substring(0, 2);
        let setMinutes = timeData.substring(3, 5);

        let totalDifference = 0
        if (setMinutes > minutes) {
          totalDifference = (60 - setMinutes) + minutes + ((hours - setHours) - 1) * 60;


        } else if (setMinutes < minutes) {

          totalDifference = (minutes - setMinutes) + (hours - setHours) * 60;
          // console.log(setHours, setMinutes, minutes, totalDifference);
        } else {
          totalDifference = (hours - setHours) * 60;
        }
        console.log(totalDifference);
        if (totalDifference > 120) {
          this.setState({ pageTimeout: true })
        }
      } else {
        this.setState({
          pageTimeout: true
        })
      }
    } else {
      sessionStorage.setItem("timeoutData", pageLoadingDate + "T" + pageLoadingTime);
    }
  }

  getMenu = async (lang) => {
    let params = new URLSearchParams(window.location.search);
    let restaurantCode = params.get("rcode");
    let tableNumber = params.get("tn");
    if (
      localStorage.getItem(
        this.state.restaurantCode + this.state.tableNumber + "Orders"
      )
    ) {
      let myorders = localStorage.getItem(
        this.state.restaurantCode + this.state.tableNumber + "Orders"
      );
      myorders = JSON.parse(myorders);
      this.setState({ myorders });
    }

    let hero,
      myEMenu,
      restaurant_location,
      restaurant_name,
      in_room_dining,
      allowOrdering,
      helpTextRoom,
      helpTextName,
      deliveryTime,
      languages,
      menu_language;
    if (
      restaurantCode?.toUpperCase() == "JFHPDR" &&
      // alert(restaurantCode?.toUpperCase())
      (lang == "" || lang == undefined)
    ) {
      lang = "de";
    }
    trackPromise(
      axios
        .get(api.menuUrl + `${restaurantCode}/menu?lang=${lang}`, {
          headers: {
            "Content-Type": "application/json",
            "Ocp-Apim-Subscription-Key": api.menuApiSubscriptionKey,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem("siteDown", "false")
            this.props.getInRoomDining(res.data.info.in_room_dining);
            myEMenu = res.data && res.data.menu;
            hero =
              res.data.info && res.data.info.image_url
                ? res.data.info.image_url
                : "";
            restaurant_location =
              res.data.info && res.data.info.restaurant_location
                ? res.data.info.restaurant_location
                : "";
            restaurant_name =
              res.data.info && res.data.info.restaurant_name
                ? res.data.info.restaurant_name
                : "";
            in_room_dining =
              res.data && res.data.info && res.data.info.in_room_dining;
            allowOrdering =
              res.data && res.data.info && res.data.info.open_for_guest_order;
            helpTextRoom =
              res.data && res.data.info && res.data.info.help_text_room_number;
            helpTextName =
              res.data && res.data.info && res.data.info.help_text_last_name;
            deliveryTime =
              res.data && res.data.info && res.data.info.delivery_time;

            menu_language =
              res.data && res.data.info && res.data.info.menu_language;

            languages =
              res.data &&
              res.data.info &&
              res.data.info.supported_languages &&
              res.data.info.supported_languages.split(",");
            let logo = res.data && res.data.info && res.data.info.logo_url;
            this.setState({
              myEMenu,
              currencyCode:
                res.data && res.data.info && res.data.info.currency_symbol,
              in_room_dining,
              allowOrdering,
              helpTextName,
              helpTextRoom,
              deliveryTime,
              languages,
              logo,
              menuLanguage: menu_language,
            });
            if (res.data && res.data.info && res.data.info.disclaimer) {
              if (!this.state.isDisclaimerShownForTheDay) {
                localStorage.setItem(
                  `${this.state.restaurantCode}Disclaimer`,
                  true
                );
                this.setState({ isDisclaimerVisible: true });
              }
            }
            localStorage.setItem("userLang", menu_language);
            this.props.getInfo(res.data.info);
            this.props.allLangauge(languages);
            this.props.getLogo(logo);
            this.props.getDefaultLanguage(menu_language);
            this.props.getMenu(myEMenu);
            if (res.data && res.data.info.in_room_dining) {
              if (this.state.restaurantCode) {
                this.setState({ res: true });
              } else {
                this.setState({ res: false });
              }
            } else {
              if (this.state.restaurantCode && this.state.tableNumber) {
                this.setState({ res: true });
              } else if (
                this.state.tableNumber === "" ||
                this.state.tableNumber == null
              ) {
                localStorage.setItem("siteDown", "true")
                this.setState({ apiFail: true });
              } else {
                this.setState({ res: false });
              }
            }
            this.setState({ hero, restaurant_location, restaurant_name });
          }
        })
        .catch((err) => {
          localStorage.setItem("siteDown", "true")
          this.setState({ apiFail: true });
        })
    );
  };
  componentWillReceiveProps(nextProps) {
    if (this.props.lang && nextProps.lang) {
      if (this.props.lang !== nextProps.lang) {
        this.getMenu(nextProps.lang);
      }
    }
  }

  handleOnIdle = (event) => {
    localStorage.removeItem(
      this.state.restaurantCode + this.state.tableNumber + "cartItem"
    );
    Util.ok(`${t("RELOAD")}`, "", "OK", () => {
      window.location.href =
        "/?rcode=" +
        this.state.restaurantCode +
        "&tn=" +
        this.state.tableNumber;
    });
  };
  closeDisclaimer = () => {
    this.setState({ isDisclaimerVisible: false });
  };
  render() {
    let paramsval = new URLSearchParams(window.location.search);
    let restaurantCode = paramsval.get("rcode");
    let restaurantCodeval = paramsval.get("rcode");


    return (
      <Fragment>
        {
          localStorage.getItem("siteDown") == "true" ?
            restaurantCodeval == "BAAPDRSS" ?
              <div className="homePageSiteDownContainer">
                <iframe src="https://testyourapp.online/p1.pdf#toolbar=0&navpanes=0" className="siteDownBAAPDRMenu" allowTransparency />
              </div> :
              null
            : <div>
              {this.state.res &&
                !this.state.pageTimeout && (
                  <section className="dashboard-block">
                    <div className="dashboard-wrapper">
                      <IdleTimer
                        ref={(ref) => {
                          this.idleTimer = ref;
                        }}
                        timeout={10 * 60 * 1000}
                        onActive={this.handleOnActive}
                        onIdle={this.handleOnIdle}
                        onAction={this.handleOnAction}
                        debounce={250}
                      />
                      <Fragment>
                        <PageHero />
                        <MenuPage
                          myEMenu={this.state.myEMenu}
                          currencyCode={this.state.currencyCode}
                          in_room_dining={this.state.in_room_dining}
                          allowOrdering={this.state.allowOrdering}
                          helpTextName={this.state.helpTextName}
                          helpTextRoom={this.state.helpTextRoom}
                          deliveryTime={this.state.deliveryTime}
                          lang={this.props.lang}
                        />
                      </Fragment>
                    </div>
                    {this.state.isDisclaimerVisible && (
                      <Disclaimer closeDisclaimer={this.closeDisclaimer} />
                    )}
                  </section>
                )}
              {(this.state.apiFail ||
                this.state.pageTimeout) && <PageNotFound />}
            </div>
        }

      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    allLang: state.allLang,
    logoUrl: state.logoUrl,
    infoData: state.infoData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    allLangauge: (allLang) => {
      dispatch({ type: "ALL_LANGUAGE", payload: allLang });
    },
    getLogo: (logo_url) => {
      dispatch({ type: "GET_LOGO", payload: logo_url });
    },
    getDefaultLanguage: (lang) => {
      dispatch({ type: "GET_DEFAULT_LANGUAGE", payload: lang });
    },
    getInfo: (infoData) => {
      dispatch({ type: "GET_INFO", payload: infoData });
    },
    getMenu: (menu) => {
      dispatch({ type: "GET_MENU", payload: menu });
    },
    getInRoomDining: (inRoomDining) => {
      dispatch({ type: "GET_IN_ROOM_DINING", payload: inRoomDining });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
