import React, { Component } from "react";
import successIcon from '../../assets/images/icons/success-icon.ico';
import "./PaymentSuccessful.scss";
// import { getTranslatedText as t } from "../../translations/index";

class PaymentSuccessful extends Component {
  render() {
    return (
      <div className="successful-block">
        <div className="successful-wrapper">
          <div className="successful-wrapper-image">
            <h1>Payment Successful</h1>
            <img src={successIcon}/>
          </div>
            <h4>Auth Code: {this.props.authCode}</h4>
          <div className="btn-wrapper">
            <button onClick={this.props.closePaymentSuccessful}>Close</button>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentSuccessful;
