const ru = {
  ADD_BTN: "ДОБАВЛЯТЬ",
  CLOSE_BTN: "ЗАКРЫТЬ",
  CHOOSE_AT_LEAST: "Выберите минимум",
  CHOOSE_UP_TO: "Выберите до",
  CHOOSE: "выберите",
  UP_TO: "до",
  INSTRUCTIONS: "Особые примечания (на английском языке)",
  PLACE_ORDER: "РАЗМЕСТИТЬ ЗАКАЗ",
  CLEAR_CART: "Очистить карту",
  CLEAR_CART_CONFIRMATION: "Вы уверены, что очистите корзину?",
  CONFIRMATION: "Подтверждение",
  SEND_ORDER: "Отправить этот заказ?",
  YES_BTN: "Да",
  NO_BTN: "Нет",
  OK_BTN: "Хорошо",
  SUCCESS_BTN: "Успешно",
  ORDER_PLACED_LABEL: "Ваш заказ был успешно оформлен и будет доставлен через",
  MINUTES: "минут",
  ORDER_PLACED: "Ваш заказ оформлен",
  UNAUTHORISED_MSG: "Order could not be placed",
  NAME_MISMATCH:
    "Name and room number entered do not match, resubmit after correction",
  UNAUTHORISED: "Connect to hotel wifi and resubmit the order",
  ERROR: "Ошибка",
  SYSTEM_ERROR: "Внутренняя системная ошибка",
  FORBIDDEN: "Запрещено",
  RESTAURANT_NOT_OPEN: "Ресторан не принимает заказы",
  RESTAURANT_CLOSED: "Ресторан закрыт для запросов",
  WENT_WRONG: "Something went wrong, contact hotel staff",
  INPUT_MSG: "Пожалуйста, заполните все поля",
  TOTAL: "Общая сумма",
  LAST_NAME: "Фамилия",
  ROOM_NUMBER: "Номер комнаты",
  GUEST_COVER_HEADING: "Заказ для ",
  GUEST_COVER_INPUT_CONTENT: "Число гостей / накрытий",
  ADD_NEW_CUSTOMIZATION: "Добавить новые предпочтения",
  PLACE_ORDER_BTN: "Разместить заказ",
  RELOAD: "Перезагрузить страницу",
  DISCLAIMER_HEADING: "отказ от ответственности",
  ENGLISH: "English",
  ARABIC: "عربى",
  RUSSIAN: "русский",
  GERMAN: "Deutsch"
};

export default ru;
