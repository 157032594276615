import React, { Component, Fragment } from "react";
import "./FoodModal.scss";
import "./FoodModalRTL.scss";
import ItemCounter from "../../../shared/ItemCounter";
import _ from "lodash";
import { getTranslatedText as t } from "../../../translations/index";

class FoodModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dummy: false,
      extras: [],
      instructions: "",
      dataToSend: "",
      cartItem: "",
      restaurantCode: "",
      tableNumber: "",
    };
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let restaurantCode = params.get("rcode");
    let tableNumber = params.get("tn");
    if (localStorage.getItem(restaurantCode + tableNumber + "cartItem")) {
      let cartItem = JSON.parse(
        localStorage.getItem(restaurantCode + tableNumber + "cartItem")
      );
      this.setState({ cartItem, restaurantCode, tableNumber });
    }
    this.setState(
      {
        dataToSend:
          this.props && this.props.dataToSend
            ? this.props.dataToSend
            : this.props.dataToEdit,
      },
      () => {
        if (this.props.dataToEdit) {
          if (
            localStorage.getItem(
              this.state.restaurantCode + this.state.tableNumber + "cartItem"
            )
          ) {
            let cart = JSON.parse(
              localStorage.getItem(
                this.state.restaurantCode + this.state.tableNumber + "cartItem"
              )
            );
            let isCustomizationPresent = false,
              item_customization,
              data_customization,
              item_comment,
              data_comment,
              currentItem;
            if (cart.length > 0) {
              for (let i in cart) {
                if (cart[i].id === this.props.dataToEdit.id) {
                  item_customization = cart[i].customizations;
                  data_customization = this.props.dataToEdit.customizations;
                  item_comment = cart[i].comments;
                  data_comment = this.props.dataToEdit.comments;
                  isCustomizationPresent =
                    _.isEqual(item_customization, data_customization) &&
                    _.isEqual(item_comment, data_comment);
                }
                if (isCustomizationPresent) {
                  currentItem = cart[i];
                  break;
                }
              }
              this.setState({ dataToSend: currentItem });
            }
          }
        }
      }
    );
  }
  onChangeHandler = (e, data) => {
    this.setState({ instructions: e.target.value }, () => {
      data.comments = this.state.instructions;
      this.setState({ dataToSend: data });
    });
  };

  render() {
    let openForGuestOrder=this.props && this.props.openForGuestOrder;
    let fields = this.state.dataToSend;
    let alreadyAddedData = [];
    this.state.cartItem &&
      this.state.cartItem.length > 0 &&
      this.state.cartItem.map((data) => {
        if (data.id === fields.id) {
          alreadyAddedData.push(data);
        }
        return 0;
      });
    let instructions = this.state.instructions
      ? this.state.instructions
      : fields.comments;
    return (
      <div
        className={`food-description-box fade-it ${
          this.props.isFoodModalOpen ? "in" : ""
        }`}
      >
        <div className="desc-inner-wrapper">
          <div
            className="close-icon"
            onClick={() => this.props.closeFoodModal(fields)}
          >
            <i className="material-icons">close</i>
          </div>

          <div className="dialogue-detail" id="dialogue-detail">
            <div
              className="food-look"
              style={{
                backgroundImage: `url(${fields && fields.image})`,
                height: `${fields && fields.image ? "220px" : ""}`,
              }}
            ></div>
            <div className="food-tag-details">
              <div className="tag allergen">
                <span>Contains Nuts</span>
              </div>
              <div className="tag allergen">
                <span>Contains Dairy</span>
              </div>
              <div className="tag">
                <span className="material-icons">star_rate</span>
                <span>Chef's Special</span>
              </div>
              <div className="tag">
                <span className="material-icons">thumb_up</span>
                <span>Popular</span>
              </div>
            </div>
            <div className="food-detail-wrapper">
              <div className="food-name">{fields && fields.name}</div>
              <div className="price">
                {this.props.currencyCode} {fields && fields.price}
              </div>
              <div className="full-desc">{fields && fields.description}</div>
            </div>
            {fields && 
            <div className="food-tag-details">
              {fields.allergy_tags &&
               fields.allergy_tags.map((data,index)=>{
                 return(
                  <div key={index}>
                    <div className="tag allergen">
                      {data.icon && <span className="material-icons"><img src={data.icon} style={{height:'20px',width:'20px',marginTop:'-10px',borderRadius:'50%'}}/></span>}
                      <span>{data.name}</span>
                    </div>
                  </div>
                 )})
              }
              {fields.food_tags &&
               fields.food_tags.map((data,index)=>{
                 return(
                   <div key={index}>
                     <div className="tag">
                      {data.icon &&
                       <span className="material-icons">
                         <img src={data.icon} style={{height:'20px',width:'20px',marginTop:'-10px',borderRadius:'50%'}}/>
                       </span>} 
                      {/* <span style={{fontWeight:'bold'}}>{data.category}{":"}</span> */}
                      <span>{data.name}</span>
                    </div>
                   </div>
                 )
               })
              }
              </div>
            }
            {fields &&
              fields.customizations &&
              fields.customizations.map((data, index) => {
                let checkCount = 0;
                let min = data.min ? data.min : 1;
                let max = data.max ? data.max : 100;
                data.checkCount = 0;
                return (
                  <Fragment key={index}>
                    {this.props.showCustomization && (
                      <div
                        className="customization-inner"
                        id={`${data.err ? "error-div" : ""}`}
                        key={index}
                      >
                        {data.name &&
                          <div className="cutom-header">
                            <h5 className="heading">{data.name}</h5>
                            {openForGuestOrder && 
                              <div>
                                {data.min && data.max === undefined && (
                                  <span
                                    className={`warning ${data.err ? "error" : ""}`}
                                  >{` ${t("CHOOSE_AT_LEAST")} ${data.min}`}</span>
                                )}
                                {data.min === undefined && data.max > 1 && (
                                  <span
                                    className={`warning ${data.err ? "error" : ""}`}
                                  >{` ${t("CHOOSE_UP_TO")} ${data.max}`}</span>
                                )}
                                {data.min === undefined && data.max === 1 && (
                                  <span
                                    className={`warning ${data.err ? "error" : ""}`}
                                  >{` ${t("CHOOSE")} ${data.max}`}</span>
                                )}
                                {data.min && data.max && data.min !== data.max && (
                                  <span
                                    className={`warning ${data.err ? "error" : ""}`}
                                  >{` ${t("CHOOSE_AT_LEAST")} ${data.min}, ${t(
                                      "UP_TO"
                                    )} ${data.max}`}</span>
                                )}
                                {data.min && data.max && data.min === data.max && (
                                  <span
                                    className={`warning ${data.err ? "error" : ""}`}
                                  >{` ${t("CHOOSE")} ${data.min}`}</span>
                                )}
                            </div>}
                          </div>
                        }
                        <div className="customization-items">
                          {data.list &&
                            data.list.map((data2, index2) => {
                              if (data2.checked) {
                                checkCount++;
                                data.checkCount = checkCount;
                              }
                              return (
                                <div className="items-wrapper" key={index2}>
                                  <label className={openForGuestOrder?"checkbox-container":""}>
                                    {!openForGuestOrder &&
                                      <div className="bullet"/>}
                                    {data2.name}
                                    {openForGuestOrder &&
                                      <input
                                      type="checkbox"
                                      name={data2.name.replace(/\s/g, "")}
                                      disabled={checkCount > max}
                                      checked={data2.checked}
                                      onChange={(e) =>
                                        this.props.handleAddOns(
                                          e,
                                          data2,
                                          data,
                                          min,
                                          max,
                                          checkCount + 1
                                        )
                                      }
                                    /> }
                                    {openForGuestOrder &&
                                      <span className="checkmark"></span>}
                                  </label>
                                  {data2.price && (
                                    <div className="item-price">
                                      {this.props.currencyCode} {data2.price}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            {this.props.showCustomization && 
              openForGuestOrder && (
              <div className="special-instructions">
                <textarea
                  className="comment-text-field"
                  placeholder={`${t("INSTRUCTIONS")}`}
                  name="instructions"
                  value={instructions}
                  onChange={(e) => this.onChangeHandler(e, fields)}
                ></textarea>
              </div>
            )}
            {!this.props.showCustomization && (
              <div className="items-list">
                {alreadyAddedData.length > 0 &&
                  alreadyAddedData.map((data, index) => {
                    let addOnPrice = 0,
                      itemPrice;
                    itemPrice = data.count * data.price;
                    return (
                      <div className="item" key={index}>
                        <div className="name-count">
                          <div className="edit-cart">
                            <i
                              className="material-icons"
                              onClick={() =>
                                this.props.editCart(
                                  data,
                                  data.id,
                                  data.count,
                                  "editCustomization"
                                )
                              }
                            >
                              edit
                            </i>
                          </div>
                          <div className="item-count">{data.count}</div>X
                          <div className="item-name">
                            <div className="name">{data.name}</div>
                            <div className="add-ons">
                              {data.customizations &&
                                data.customizations.map((data2, index2) => {
                                  return (
                                    <div className="group" key={index2}>
                                      {data2 &&
                                        data2.list &&
                                        data2.list.map((data3, index3) => {
                                          if (data3.checked) {
                                            if (data3.price) {
                                              addOnPrice =
                                                addOnPrice + data3.price;
                                            }
                                          }
                                          return (
                                            <Fragment key={index3}>
                                              {data3.checked && (
                                                <Fragment>
                                                  <span key={index3}>
                                                    {data3.name}
                                                  </span>
                                                  <span className="seperator">
                                                    ,{" "}
                                                  </span>
                                                </Fragment>
                                              )}
                                            </Fragment>
                                          );
                                        })}
                                    </div>
                                  );
                                })}
                            </div>
                            {data.comments && (
                              <div className="comments">{data.comments}</div>
                            )}
                          </div>
                        </div>
                        <div className="price">
                          {this.props.currencyCode} {itemPrice + addOnPrice}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
          {!this.props.showCustomization && (
            <div className="new-customization">
              <button
                onClick={() =>
                  this.props.addCustomization(
                    this.props.dataToSend.id,
                    this.state.dataToSend.count
                      ? this.state.dataToSend.count
                      : 0
                  )
                }
              >
                + {t("ADD_NEW_CUSTOMIZATION")}
              </button>
            </div>
          )}
          {this.props.showCustomization &&
            openForGuestOrder && (
            <ItemCounter
              itemData={this.state.dataToSend}
              handleItemCounter={this.props.handleItemCounter}
              noOfItems={this.props.noOfItems}
              closeFoodModal={this.props.closeFoodModal}
              setCartValue={this.props.setCartValue}
              currencyCode={this.props.currencyCode}
              allowOrdering={this.props.allowOrdering}
            />
          )}
        </div>
      </div>
    );
  }
}

export default FoodModal;


