import React, { Component, Fragment } from "react";
import "./PageHeader.scss";
import "./PageHeaderRTL.scss";
import Logo from "../Logo";
import MyOrders from "../MyOrders";
import LanguageSelector from "../LanguageSelector";
import Disclaimer from "../../shared/Disclaimer";
import { connect } from "react-redux";

class PageHeader extends Component {
  state = {
    showOrdersTab: false,
    myorders: "",
    isDisclaimerVisible: false,
    restaurantCode: "",
    tableNumber: "",
    refId: "",
  };
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let restaurantCode = params.get("rcode");
    let tableNumber = params.get("tn");
    let refId = params.get("ref");
    this.setState({ restaurantCode, tableNumber, refId });
    if (localStorage.getItem(restaurantCode + tableNumber + "Orders")) {
      let myorders = localStorage.getItem(
        restaurantCode + tableNumber + "Orders"
      );
      myorders = JSON.parse(myorders);
      this.setState({ myorders });
    }
    if (refId) {
      this.setState({ showOrders: true });
    }
  }
  showOrders = () => {
    this.setState({ showOrders: true });
  };
  closeTab = () => {
    this.setState({ showOrders: false }, () => {
      if (this.state.refId) {
        window.location.href =
          "/?rcode=" +
          this.state.restaurantCode +
          "&tn=" +
          this.state.tableNumber;
      }
    });
  };
  showDisclaimer = () => {
    this.setState({ isDisclaimerVisible: true });
  };
  closeDisclaimer = () => {
    this.setState({ isDisclaimerVisible: false });
  };
  render() {
    let openForGuestOrder=this.props && 
      this.props.infoData && 
        this.props.infoData.open_for_guest_order;
    return (
      <Fragment>
        <header>
          <div className="utility-bar">
            <LanguageSelector />
          </div>
          <div className="header-wrapper">
            <div className="left-block">
              <Logo />
            </div>

            <div className="right-block">
              {this.props.infoData && this.props.infoData.disclaimer && (
                <div className="info-wrapper" onClick={this.showDisclaimer}>
                  <span class="material-icons">report_problem</span>
                </div>
              )}
              {openForGuestOrder &&
                <button className="orders" onClick={this.showOrders}>
                My Orders
              </button>}
            </div>
          </div>
        </header>
        <MyOrders expand={this.state.showOrders} closeTab={this.closeTab} />
        {this.state.isDisclaimerVisible && (
          <Disclaimer closeDisclaimer={this.closeDisclaimer} />
        )}
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    infoData: state.infoData,
  };
};
export default connect(mapStateToProps, null)(PageHeader);
