import React, { Component } from "react";
import "./Disclaimer.scss";
import { connect } from "react-redux";
import { getTranslatedText as t } from "../../translations/index";

class Disclaimer extends Component {
  render() {
    return (
      <div className="disclaimer-block">
        <div className="disclaimer-wrapper">
          <div className="heading">{t("DISCLAIMER_HEADING")}</div>
          <div className="menu-hr"></div>
          <div className="content">
            {this.props.infoData &&
              this.props.infoData.disclaimer &&
              this.props.infoData.disclaimer.map((data, index) => {
                return (
                  <p key={index}>
                    {data.text}
                    <hr />
                  </p>
                );
              })}
          </div>
          <div className="btn-wrapper">
            <button onClick={this.props.closeDisclaimer}>{t("OK_BTN")}</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoData: state.infoData,
  };
};

export default connect(mapStateToProps, null)(Disclaimer);
