import React from "react";
import "./PageNotFound.scss";

const PageNotFound = () => {
    return(
        <section className="page-not-found-block">
            <div className="page-not-found-wrapper">
                <h1>Page Not Found</h1>
                <small>This page does not exist</small>
                <div className="message">Please scan the QR code again</div>
            </div>
        </section>
    )
}

export default PageNotFound;