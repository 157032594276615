import React, { useEffect, useState } from "react";
import "./App.scss";
import "./assets/app.scss";
import "./assets/base.scss";
import api from "./util/api";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import PageHeader from "./components/PageHeader";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  //let params = useQuery();
  // const [emenuStatus,setEmenuStatus] = useState(false);
  let params = new URLSearchParams(window.location.search);

  let referenceCode = params.get("ref");
  let restaurantCode = params.get("rcode");

  let tableNumber = params.get("tn");
  useEffect(() => {
    //  alert(params);
    //  alert(referenceCode);

    //  alert(restaurantCode)
    if (
      // restaurantCode?.toUpperCase() !== "JPSINFY"
      // && restaurantCode?.toUpperCase() !== "JPSSTP"
      // && restaurantCode?.toUpperCase() !== "JPSPDR"
      // && restaurantCode?.toUpperCase() !== "JCTPDR"
      // && restaurantCode?.toUpperCase() !== "JMBMNT"
      // && restaurantCode?.toUpperCase() !== "JFHPDR"
      // && !restaurantCode?.includes("PDR")
      // restaurantCode?.toUpperCase() !== "JPSINFY"
      // && restaurantCode?.toUpperCase() !== "JPSSTP"
      // && restaurantCode?.toUpperCase() !== "JPSPDR"
      // restaurantCode?.toUpperCase() !== "BAAPDR"
      restaurantCode?.toUpperCase() !== "BAAPDRSS"
      // && restaurantCode?.toUpperCase() !== "JCTPDR"
      // && restaurantCode?.toUpperCase() !== "JFHPDR"
      // && restaurantCode?.toUpperCase() !== "ALNPDR"
      // && restaurantCode?.toUpperCase() !== "AQPDR"
      // && restaurantCode?.toUpperCase() !== "DAMPDR"
      // && restaurantCode?.toUpperCase() !== "MLKPDR"
      // && restaurantCode?.toUpperCase() !== "MASPDR"
      // && restaurantCode?.toUpperCase() !== "JMBMNT"

      // && !restaurantCode?.includes("PDR")
    ) {
      if (referenceCode) {
        // if (!localStorage.getItem("presentReference")) {
        // alert(restaurantCode);
        // window.location.replace(`https://jg-emenu.azurewebsites.net/#/?ref=${referenceCode}`);
        // localStorage.setItem("presentReference", referenceCode);
        window.location.replace(
          `https://${api.redirect_urls}/emenu-latest/#/?ref=${referenceCode}&rcode=${restaurantCode}&tn=${tableNumber}`
        );
        // }
      } else {
        // localStorage.removeItem("presentReference");
        window.location.replace(
          `https://${api.redirect_urls}/emenu-latest/#/?rcode=${restaurantCode}&tn=${tableNumber}`
        );
      }
      //   alert(restaurantCode);
      //  alert(referenceCode);
      //   // setEmenuStatus(true);
      //   window.location.replace(`https://emenu.jumeirah.com/emenu-latest/#/?rcode=${restaurantCode}&tn=${tableNumber}`);
    }
    // else if(restaurantCode === "AQPOOL" && referenceCode){
    //   // setEmenuStatus(true);
    //   alert(restaurantCode);
    //   window.location.replace(`https://emenu.jumeirah.com/emenu-latest/#/?ref=${referenceCode}&rcode=${restaurantCode}&tn=${tableNumber}`);
    // }
    else {
      // setEmenuStatus(false);
      // localStorage.removeItem("presentReference");
    }
  }, []);
  return (
    <BrowserRouter>
      <div className="App" id="App">
        <PageHeader />
        <Switch>
          <Route exact path="/" component={Dashboard} />
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
