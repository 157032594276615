import React, {Component} from 'react';
import failedIcon from '../../assets/images/icons/failed.svg';
import "./PaymentFailed.scss";

class PaymentFailed extends Component{
    render() {
        return (
          <div className="successful-block">
            <div className="successful-wrapper">
              <div className="successful-wrapper-image">
                <h1>Payment Failed</h1>
                <img src={failedIcon}/>
              </div>
              <div className="btn-wrapper">
                <button onClick={this.props.closePaymentFailed}>Close</button>
              </div>
            </div>
          </div>
        );
    }
}

export default PaymentFailed;