import en from "./en";
import ru from "./ru";
import ar from "./ar";
import { store } from "../index";

const translations = {
  en,
  ar,
  ru,
};

export const getTranslatedText = (key, locale) => {
  const state = store.getState();
  locale = state.lang;
  const currTranslation = translations[locale] ? translations[locale] : en;
  let translatedText = currTranslation[key]
    ? currTranslation[key]
    : en[key]
    ? en[key]
    : key;

  return translatedText;
};
