import React, { Component, Fragment } from "react";
import "./MyOrders.scss";
import "./MyOrdersRTL.scss";
import { connect } from "react-redux";
import PaymentModule from "../PaymentModule";

class MyOrders extends Component {
  state = {
    myorders: "",
    selectedIndex: -1,
    isOrderListVisible:true,
  };
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let restaurantCode = params.get("rcode");
    let tableNumber = params.get("tn");
    if (localStorage.getItem(restaurantCode + tableNumber + "Orders")) {
      let myorders = localStorage.getItem(
        restaurantCode + tableNumber + "Orders"
      );
      myorders = JSON.parse(myorders);
      this.setState({ myorders });
    }
  }
  toggleOrder = (index) => {
    if (index === this.state.selectedIndex) {
      index = -1;
    }
    this.setState({
      selectedIndex: index,
    });
  };

  render() {
    let inRoomDining=this.props && 
      this.props.infoData && 
      this.props.infoData.in_room_dining;
    let onlinePaymentEnabled=this.props && 
      this.props.infoData && 
      this.props.infoData.payment_online_enabled;
    let roomPaymentEnabled=this.props && 
      this.props.infoData && 
      this.props.infoData.payment_to_room_enabled;
    return (
      <section
        className={`my-orders-block ${this.props.expand ? "expand" : ""}`}
      >
        <div className="my-orders-wrapper">
          <div className="close" onClick={this.props.closeTab}>
            <i className="material-icons">close</i>
          </div>
          <div className="heading">My Orders</div>
          <div className="menu-hr"></div>
          {this.state.isOrderListVisible && 
          <div className="order-list">
            {this.state.myorders && this.state.myorders.length > 0 ? (
              this.state.myorders.map((data, index) => {
                let date=new Date(Date.parse(data.orderDetails.execution_time));
                let hours = date.getHours();
                let minutes = date.getMinutes();
                hours = hours > 9 ? hours : "0" + hours;
                minutes = minutes > 9 ? minutes : "0" + minutes;
                let time = hours + ":" + minutes;
                let totalPrice = 0,
                  itemPrice1 = 0;
                data.items &&
                  data.items.length > 0 &&
                  data.items.map((data) => {
                    let addOnPrice1 = 0;
                    data.customizations &&
                      data.customizations.length > 0 &&
                      data.customizations.map((data2) => {
                        data2 &&
                          data2.list &&
                          data2.list.map((data3) => {
                            if (data3.checked) {
                              if (data3.price) {
                                addOnPrice1 =
                                  data.count * (addOnPrice1 + data3.price);
                              }
                            }
                            return 1;
                          });
                        return 1;
                      });
                    itemPrice1 = data.count * data.price;
                    totalPrice = totalPrice + itemPrice1 + addOnPrice1;
                    return 1;
                  });
                return (
                  <div className="orders-wrapper" key={index}>
                    <div
                      className="order"
                      onClick={() => this.toggleOrder(index)}
                    >
                      <div className="left">
                        <div className="time">{time}</div>
                      </div>
                      <div className="mid">
                        <div className="price">
                          {this.props.infoData.currency_symbol} {totalPrice}
                        </div>
                      </div>
                      <div className="right">
                        {this.state.selectedIndex === index ? (
                          <span class="material-icons">remove</span>
                        ) : (
                          <span className="material-icons">add</span>
                        )}
                      </div>
                    </div>
                    <div
                      className={`body ${
                        this.state.selectedIndex === index ? "show-body" : ""
                      }`}
                    >
                      <div className="items-wrapper">
                        <div className="items-list">
                          {data.items &&
                            data.items.map((data2, index2) => {
                              let addOnPrice = 0,
                                itemPrice;
                              itemPrice = data2.count * data2.price;
                              return (
                                <div className="item" key={index2}>
                                  <div className="name-count">
                                    <div className="item-count">
                                      {data2.count}
                                    </div>
                                    X
                                    <div className="item-name">
                                      <div className="name">{data2.name}</div>
                                      <div className="add-ons">
                                        {data2.customizations &&
                                          data2.customizations.map(
                                            (data3, index3) => {
                                              return (
                                                <div
                                                  className="group"
                                                  key={index3}
                                                >
                                                  {data3 &&
                                                    data3.list &&
                                                    data3.list.map(
                                                      (data4, index4) => {
                                                        if (data4.checked) {
                                                          if (data4.price) {
                                                            addOnPrice =
                                                              data2.count *
                                                              (addOnPrice +
                                                                data4.price);
                                                          }
                                                        }
                                                        return (
                                                          <Fragment
                                                            key={index4}
                                                          >
                                                            {data4.checked && (
                                                              <Fragment>
                                                                <span
                                                                  key={index4}
                                                                >
                                                                  {data4.name}
                                                                </span>
                                                                <span className="seperator">
                                                                  ,{" "}
                                                                </span>
                                                              </Fragment>
                                                            )}
                                                          </Fragment>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                      {data2.comments && (
                                        <div className="comments">
                                          {data2.comments}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="price">
                                    {this.props.infoData.currency_symbol}{" "}
                                    {itemPrice + addOnPrice}
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                   
                  </div>
                );
              })
            ) : (
              <div className="no-orders">No Orders Placed Yet</div>
            )}
        </div>}
            {!inRoomDining &&
              (onlinePaymentEnabled || roomPaymentEnabled) &&
              <PaymentModule />
            }
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    infoData: state.infoData,
  };
};

export default connect(mapStateToProps, null)(MyOrders);
