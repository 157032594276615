import React, { Component } from 'react';
import { getTranslatedText as t } from "../../../translations/index";
import './VerificationModal.scss';

class VerificationModal extends Component{
    state={
        firstName:"",
        lastName:"",
        email:"",
        emailIsValid:false,
        table:"",
        isVerify:true,
        checked:false,
    }

    verifyDetails=(e)=>{
        e.preventDefault();
        this.verifyRoomDetails();
    }

    verifyRoomDetails=()=>{
        this.setState({isVerify:false})
    }

    handleInputChange=(e)=>{
        if(e.target.name==="email"){
            let test=e.target.value.match(/^\w+([.-]\w+)*@\w+([.-]\w+)*\.\w{2,3}$/);
            this.setState({ [e.target.name]: e.target.value,emailIsValid:test});
        }else{
            this.setState({ [e.target.name]: e.target.value});
        }
    }

    handleCheckBoxChange=(e)=>{
        this.setState({ checked:e.target.checked });
    }

    onVerify=()=>{
        this.setState({isVerify:false})
        this.props.createOrder(
            this.props.tipAmount,
            this.props.checks,
            this.props.currencyCode,
            this.state.firstName,
            this.state.lastName,
            this.state.email);
    }

    render(){
        return(
                this.state.isVerify && <div className="modals">
                <div className="modals-content">
                    <div onClick={this.props.payInRoom} className="close-icon">X</div>
                    <h3>Guest Details</h3>
                    <form>
                    <div className="guest-covers">
                        <div className="cover-input">
                            <label>
                                First Name
                                <span style={{ color:"red",paddingLeft:"5px"}}>
                                    *
                                </span>
                            </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    value={this.state.firstName}
                                    onChange={this.handleInputChange}
                                />
                        </div>
                        <div className="cover-input">
                            <label>
                                {t("LAST_NAME")}
                                <span style={{ color:"red",paddingLeft:"5px"}}>
                                    *
                                </span>
                            </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    value={this.state.lastName}
                                    onChange={this.handleInputChange}
                                />
                        </div>
                        <div className="cover-input">
                            <label>
                                Email
                                <span style={{ color: "red", paddingLeft: "5px" }}>
                                    *
                                </span>
                            </label>
                            <input
                                type="text"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                            />
                        </div>
                        </div>
                        <div className={`order-btn ${
                            (this.state.firstName && this.state.lastName && this.state.email && this.state.emailIsValid)
                            ? "active"
                            : ""
                        }`} onClick={this.onVerify}>
                            <input type="button" value="SUBMIT"/>
                        </div>
                        <div className="note">
                         Note: This information is used only for payment purposes
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default VerificationModal;