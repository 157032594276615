import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; 
import { ToastsStore } from "react-toasts";

// Util.js contains the basic localstorage functions
export class Util {
  static confirm(
    title,
    message,
    btnYestLbl,
    btnNoLbl,
    btnYesClickFun,
    btnNoClickFun
  ) {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: btnYestLbl,
          onClick: btnYesClickFun,
        },
        {
          label: btnNoLbl,
          onClick: btnNoClickFun,
        },
      ],
    });
  }
  static ok(title, message, btnYestLbl, btnYesClickFun) {
    confirmAlert({
      title: title,
      message: message,
      buttons: [
        {
          label: btnYestLbl,
          onClick: btnYesClickFun,
        },
      ],
    });
  }
  static showToastMessage(msg, type) {
    if (type) {
      if (type === "s") {
        ToastsStore.success(msg);
      } else if (type === "e") {
        ToastsStore.error(msg);
      } else if (type === "w") {
        ToastsStore.warning(msg);
      }
    } else {
      ToastsStore.warning(msg);
    }
  }
  static uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (
      c
    ) {
      var r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }
 
}
