// export default {
//   menuUrl: "https://apim.hc.dubaiholding.com/restaurants/info/v1/", //uat
//   orderUrl: "https://apim.hc.dubaiholding.com/restaurants/order/v1/", // uat

//   menuApiSubscriptionKey: "2b87b697ba77412d9e3de71f2d66c11f", //uat
//   orderApiSubscriptionKey: "6c5d1d1fc52543b0aaa54984b2d02ff1", //uat

//   // menuUrl: "https://dh-dev-apim.azure-api.net/restaurants/info/v1/", //dev new
//   // orderUrl: "https://dh-dev-apim.azure-api.net/restaurants/order/v1/",//dev new

//   // menuApiSubscriptionKey:"c5f67862202f4577ab36a72ad04b38ca",//dev new
//   // orderApiSubscriptionKey:"735c0ee1f22f46bca54a62ab0e9f5fb3",//dev new

//   accessTokenUrl:
//     "https://apim.hc.dubaiholding.com/jumeirah/payment/v1/identity/auth/access-token",

//   paymentApiSubscriptionKey: "574530b54bc44b45a2f8cbeec3f38288",

//   transactionOutletUrl:
//     "https://apim.hc.dubaiholding.com/jumeirah/payment/v1/transactions/outlets/d0275187-3579-49dd-ab9a-b17f6d45b5e2/orders"
// };

export default {
  menuUrl: "https://apim.hc.dubaiholding.com/restaurants/info/v1/", // prod
  orderUrl: "https://apim.hc.dubaiholding.com/restaurants/order/v1/", // prod

  menuApiSubscriptionKey: "3d586a2e59254d3c99ca6c152ef6c483", // prod
  orderApiSubscriptionKey: "6c5d1d1fc52543b0aaa54984b2d02ff1", // prod
  redirect_urls: 'emenu.jumeirah.com', // prod



  // menuUrl: "https://apim-uat.hc.dubaiholding.com/restaurants/info/v1/", //uat
  // orderUrl: "https://apim-uat.hc.dubaiholding.com/restaurants/order/v1/", //uat

  // menuApiSubscriptionKey: "ab62d4edfdcd4b9c98a11ea7de0d46a7", //uat
  // orderApiSubscriptionKey: "c728b8b024734c99aeb5a2d6ea7f2140", //uat
  // redirect_urls: 'jg-emenu.azurewebsites.net', //uat



  // menuApiSubscriptionKey: "ab62d4edfdcd4b9c98a11ea7de0d46a7", //uat
  // orderApiSubscriptionKey: "c728b8b024734c99aeb5a2d6ea7f2140", //uat





  //menuUrl: "https://apim-dh-dev.azure-api.net/restaurants/info/v1/", //dev
  //orderUrl: "https://apim-dh-dev.azure-api.net/restaurants/order/v1/", //dev


  accessTokenUrl:
    "https://apim.hc.dubaiholding.com/jumeirah/payment/v1/identity/auth/access-token",

  paymentApiSubscriptionKey: "574530b54bc44b45a2f8cbeec3f38288",

  transactionOutletUrl:
    "https://apim.hc.dubaiholding.com/jumeirah/payment/v1/transactions/outlets/d0275187-3579-49dd-ab9a-b17f6d45b5e2/orders",

};
