const en = {
    ADD_BTN: "ADD",
    CLOSE_BTN: "CLOSE",
    CHOOSE_AT_LEAST: "Choose at least",
    CHOOSE_UP_TO: "Choose up to",
    CHOOSE: "Choose",
    UP_TO: "up to",
    INSTRUCTIONS: "Special Instructions (In English)",
    PLACE_ORDER: "PLACE YOUR ORDER",
    CLEAR_CART: "Clear Cart",
    CLEAR_CART_CONFIRMATION: "Are you sure to clear the cart ?",
    CONFIRMATION: "Confirmation",
    SEND_ORDER: "Send this order ?",
    YES_BTN: "Yes",
    NO_BTN: "No",
    OK_BTN: "OK",
    SUCCESS_BTN: "Success",
    ORDER_PLACED_LABEL:
      "Your order has been placed and will be delivered to you within the next",
    MINUTES: "minutes",
    ORDER_PLACED: "Your order has been placed",
    UNAUTHORISED_MSG: "Order could not be placed",
    NAME_MISMATCH: "Name and room number entered do not match, resubmit after correction",
    UNAUTHORISED: "Connect to hotel wifi and resubmit the order",
    ERROR: "Error",
    SYSTEM_ERROR: "Internal System Error",
    FORBIDDEN: "Forbidden",
    RESTAURANT_NOT_OPEN: "Restaurant not open for ordering",
    RESTAURANT_CLOSED: "Restaurant is closed for orders",
    WENT_WRONG: "Something went wrong, contact hotel staff",
    INPUT_MSG: "Please input all the fields",
    TOTAL: "Total",
    LAST_NAME: "Last Name",
    ROOM_NUMBER: "Room Number",
    GUEST_COVER_HEADING: "Ordering for",
    GUEST_COVER_INPUT_CONTENT: "Number of guests/people",
    ADD_NEW_CUSTOMIZATION: "Add new customization",
    PLACE_ORDER_BTN: "Place Order",
    RELOAD: "Reload Page",
    DISCLAIMER_HEADING: "DISCLAIMER",
    ENGLISH: "English",
    ARABIC: "العربي",
    RUSSIAN: "Русский",
  GERMAN: "Deutsch"
  };
  
  export default en;
  