import React, { Component, Fragment } from "react";
import "./LanguageSelector.scss";
import "./LanguageSelectorRTL.scss";
import { connect } from "react-redux";
import { getTranslatedText as t } from "../../translations/index";
import Arrow from "../../assets/images/icons/arrow.png";

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedLang: "",
      defaultLanguage: "",
      defaultLanguageCode: "",
      selectedLanguage: "",
      selectedLangCode: "",
      resCode: "",
      tableNumber: "",
    };
  }
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let resCode = params.get("rcode");
    let tableNumber = params.get("tn");
    this.setState({ resCode, tableNumber });
  }

  toggle = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen }, () => {
      document.addEventListener("click", this.closeDropdown);
    });
  };

  closeDropdown = () => {
    this.setState({ dropdownOpen: false }, () => {
      document.removeEventListener("click", this.closeDropdown);
    });
  };
  handleSelectLanguage = (data) => {
    this.setState(
      {
        selectedLanguage: data.lang,
        selectedLangCode: data.langCode,
        defaultLanguage: "",
      },
      () => {
        localStorage.removeItem(
          this.state.resCode + this.state.tableNumber + "cartItem"
        );
        localStorage.setItem("userLang", this.state.selectedLangCode);
        this.props.changeLanguage(this.state.selectedLangCode);
        document.documentElement.lang = this.state.defaultLanguage
          ? this.state.defaultLanguageCode
          : this.state.selectedLangCode;
        if (
          this.state.defaultLanguageCode === "ar" ||
          this.state.selectedLangCode === "ar"
        ) {
          document.querySelector("html").setAttribute("dir", "rtl");
          document.getElementById("App").classList.add("lang-rtl");
        } else {
          document.querySelector("html").removeAttribute("dir");
          document.getElementById("App").classList.remove("lang-rtl");
        }
      }
    );
  };
  render() {
    let langArray = [];
    this.props.allLang &&
      this.props.allLang.length > 0 &&
      this.props.allLang.map((data) => {
        let l;
        if (data === "en") {
          l = {
            lang: `${t("ENGLISH")}`,
            langCode: "en"
          };
          langArray.push(l);
        } else if (data === "ar") {
          l = {
            lang: `${t("ARABIC")}`,
            langCode: "ar"
          };
          langArray.push(l);
        } else if (data === "ru") {
                 l = {
                   lang: `${t("RUSSIAN")}`,
                   langCode: "ru"
                 };
                 langArray.push(l);
               } 
               else if (data === "zh") {
                 l = {
                   lang: `${t("CHINESE")}`,
                   langCode: "zh"
                 };
                 langArray.push(l);
               } 
               else if (data === "de") {
                 l = {
                   lang: `${t("GERMAN")}`,
                   langCode: "de"
                 };
                 langArray.push(l);
               }
        return l;
      });
    let firstLanguageCode = this.state.selectedLangCode
      ? this.state.selectedLangCode
      : this.props.lang;
    let language_to_show;
    if (firstLanguageCode === "en") {
      language_to_show = `${t("ENGLISH")}`;
    } else if (firstLanguageCode === "ru") {
      language_to_show = `${t("RUSSIAN")}`;
    } else if (firstLanguageCode === "de") {
      language_to_show = `${t("GERMAN")}`;
    } else if (firstLanguageCode === "ar") {
      language_to_show = `${t("ARABIC")}`;
    } else if (firstLanguageCode === "zh") {
      language_to_show = `${t("CHINESE")}`;
    }
    return (
      <Fragment>
        {this.props.allLang && this.props.allLang.length > 1 && (
          <div className="language-selector-block">
            <div className="language-selector-wrapper">
              {this.props.allLang.length <= 3 ? (
                <ul
                  className={`dropdown ${
                    this.state.dropdownOpen ? "show" : ""
                  }`}
                >
                  {langArray &&
                    langArray.length > 0 &&
                    langArray.map((data, index) => {
                      return (
                        <li
                          key={index}
                          onClick={() => this.handleSelectLanguage(data)}
                          className={`${
                            language_to_show === data.lang ? "active" : ""
                          }`}
                        >
                          {data.lang}
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="menu-inner-wrapper">
                  <div
                    className="display-label-wrapper display-label-wrapper-german"
                    onClick={this.toggle}
                  >
                    <span className="display-name">{language_to_show}</span>
                    <span
                      className={`dropdown-icon ${
                        this.state.dropdownOpen ? "rotate" : ""
                      }`}
                    >
                      <img src={Arrow} alt="down-arrow" />
                    </span>
                  </div>
                  <div
                    className={`dropdown-content dropdown-contentnew ${
                      this.state.dropdownOpen ? "show-dropdown" : ""
                    }`}
                  >
                      <ul
                        className={`dropdown ${
                          this.state.dropdownOpen ? "show" : ""
                          }`}
                      >
                        {langArray &&
                          langArray.length > 0 &&
                          langArray.map((data, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => this.handleSelectLanguage(data)}
                                className={`${
                                  language_to_show === data.lang ? "active" : ""
                                  }`}
                              >
                                {data.lang}
                              </li>
                            );
                          })}
                      </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.lang,
    allLang: state.allLang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (name) => {
      dispatch({ type: "CHANGE_LANGUAGE", payload: name });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector);
