import React, { Component, Fragment } from "react";
import "./ItemCounter.scss";
import { getTranslatedText as t } from "../../translations/index";

class ItemCounter extends Component {
  render() {
    let totalPrice = 0,
      itemPrice = 0,
      addOnPrice = 0;
    itemPrice = this.props.noOfItems * this.props.itemData.price;
    this.props.itemData &&
      this.props.itemData.customizations &&
      this.props.itemData.customizations.map((data) => {
        data.list &&
          data.list.map((data2) => {
            if (data2.checked) {
              if (data2.price) {
                addOnPrice = this.props.noOfItems * (addOnPrice + data2.price);
              }
            }
            return addOnPrice;
          });
        return 0;
      });
    totalPrice = itemPrice + addOnPrice;

    return (
      <Fragment>
        {this.props.allowOrdering && (
          <div className="add-to-cart-wrapper">
            <div className="item-counter-wrapper">
              <button
                className={`decrement-btn ${
                  this.props.noOfItems === 0 ? "disable" : ""
                }`}
                onClick={() =>
                  this.props.handleItemCounter("minus", this.props.itemData)
                }
              >
                -
              </button>

              <span>{this.props.noOfItems ? this.props.noOfItems : 0}</span>
              <button
                className="increment-btn"
                onClick={() =>
                  this.props.handleItemCounter("plus", this.props.itemData)
                }
              >
                +
              </button>
            </div>
            <div
              className="add-btn"
              onClick={() => this.props.setCartValue(this.props.itemData)}
            >
              <span className="label">
                {this.props.noOfItems > 0
                  ? `${t("ADD_BTN")}`
                  : `${t("CLOSE_BTN")}`}
              </span>
              <span className="price">
                {this.props.currencyCode} {totalPrice}
              </span>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default ItemCounter;
