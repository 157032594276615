const ar = {
  ADD_BTN: "اضافه",
  CLOSE_BTN: "اغلاق",
  CHOOSE_AT_LEAST: "اختر على الأقل",
  CHOOSE_UP_TO: "اختر حتى",
  CHOOSE: "أختراختر",
  UP_TO: "يصل إلى",
  INSTRUCTIONS: "تعليمات خاصة (باللغة الإنجليزية)",
  PLACE_ORDER: "ضع طلبك",
  CLEAR_CART: "مسح السلة",
  CLEAR_CART_CONFIRMATION: "هل أنت متأكد من مسح العربة؟",
  CONFIRMATION: "التأكيد",
  SEND_ORDER: "أرسل هذا الطلب؟",
  YES_BTN: "نعم",
  NO_BTN: "لا",
  OK_BTN: "نعم",
  SUCCESS_BTN: "نجاح",
  ORDER_PLACED_LABEL: "تم تقديم طلبك وسيتم تسليمه لك في غضون اليوم التالي",
  MINUTES: "الدقائق",
  ORDER_PLACED: "تم استلام طلبك",
  UNAUTHORISED_MSG: "Order could not be placed",
  NAME_MISMATCH: "Name and room number entered do not match, resubmit after correction",
  UNAUTHORISED: "Connect to hotel wifi and resubmit the order",
  ERROR: "خطأ",
  SYSTEM_ERROR: "خطأ نظام داخلي",
  FORBIDDEN: "المحرمه",
  RESTAURANT_NOT_OPEN: "مطعم غير مفتوح للطلب",
  RESTAURANT_CLOSED: "المطعم مغلق للطلبات",
  WENT_WRONG: "Something went wrong, contact hotel staff",
  INPUT_MSG: "الرجاء إدخال كافة الحقول",
  TOTAL: "المجموع ",
  LAST_NAME: "اسم العائلة ",
  ROOM_NUMBER: "رقم الغرفه ",
  GUEST_COVER_HEADING: "طلب ل",
  GUEST_COVER_INPUT_CONTENT: "عدد الضيوف/ الأغطية",
  ADD_NEW_CUSTOMIZATION: "إضافة تخصيص جديد",
  PLACE_ORDER_BTN: "اطلب الان",
  RELOAD: "إعادة تحميل الصفحة",
  DISCLAIMER_HEADING: "تنصل",
  ENGLISH: "English",
  ARABIC: "العربي",
  GERMAN: "Deutsch",
  RUSSIAN: "Русский",
  GERMAN: "Deutsch"
};

export default ar;
